const $ = require("jquery");
import "core-js/stable";
import "regenerator-runtime/runtime";

window.isSP = function () {
  return $(".u-dp-s:visible").length;
};
const LazyLoad = require("lazyload"),
  SmoothScroll = require("smooth-scroll");

// Picturefill
document.createElement("picture");

// LazyLoad
new LazyLoad({
  elements_selector: ".lazyload",
});
// Smoothscroll
SmoothScroll('a[href*="#"]', {
  updateURL: false,
});

/*
 * Const close_sp_menu = () => {
 *   $("body").removeClass("_gnav-show_") -a
 *   $(".c-headerMenu").removeClass("_active_");
 * };
 */

$(function () {
  // PCのみ適用させたい内容を記述
  $(window).scroll(function () {
    if (!window.isSP()) {
      if ($(window).width() <= 1024) return;
      if ($(window).scrollTop() > 80) {
        $("body").addClass("_gnav-show_");
        $(".c-gnavMenu").addClass("_active_");
      } else {
        $("body").removeClass("_gnav-show_");
        $(".c-gnavMenu").removeClass("_active_");
      }
    }
  });

  $(".c-gnavMenu3__link._down_").on("click", function () {
    if (window.isSP()) {
      $(this).next().slideToggle();
      $(this).toggleClass("_drop_");
    }
  });
  $("span.c-footerFnav__link").on("click", function () {
    if (window.isSP()) {
      $(this).next().slideToggle();
      $(this).toggleClass("_active_");
    }
  });

  // Accordion
  $(".m-accordion").on("click", function () {
    $(this).next().slideToggle();
    $(this).toggleClass("_active_");
  });
});

// 固定header
$(function () {
  // SP header menu
  $(".c-gnavMenu").on("click", function () {
    $(".c-gnavMenu").toggleClass("_active_");
    $("body").toggleClass("_gnav-show_");
  });
});

// Tel link
const ua = navigator.userAgent.toLowerCase(),
  isMobile = /iphone/u.test(ua) || /android(?<ua>.+)?mobile/u.test(ua);

if (!isMobile) {
  $('a[href^="tel:"]').on("click", function (e) {
    e.preventDefault();
  });
}

const change_window_height = () => {
    const $gnav = $("div.c-gnav");
    if ($(window).height() > 700) {
      if (!$gnav.hasClass("_nav-fixed_")) $gnav.addClass("_nav-fixed_");
    } else if ($gnav.hasClass("_nav-fixed_")) $gnav.removeClass("_nav-fixed_");

    if (!window.isSP()) {
      $(".c-gnavMenu3__link._down_").each(function (idx, val) {
        $(val).next().removeAttr("style");
        $(val).removeClass("_drop_");
      });
    }
  },
  /* eslint max-lines-per-function: ["error", 60] */
  menu_show_delay = (element, delay_time) => {
    let sethover = null,
      setleave = null,
      setnexthover = null,
      targetOn = null,
      targetOff = null,
      nowActive = -1;
    const hoverClass = "_active_",
      manuElement = element,
      hoverTime = delay_time;
    manuElement.on({
      mouseenter() {
        if (window.isSP()) return;
        targetOn = $(this);
        if (nowActive === -1) {
          sethover = setTimeout(function () {
            targetOn.addClass(hoverClass);
            nowActive = manuElement.index(targetOn);
          }, hoverTime);
        } else if (targetOn.hasClass(hoverClass)) {
          clearTimeout(setleave);
        } else {
          setnexthover = setTimeout(function () {
            manuElement.removeClass(hoverClass);
            targetOn.addClass(hoverClass);
            nowActive = manuElement.index(targetOn);
          }, hoverTime);
        }
      },
      mouseleave() {
        if (window.isSP()) return;
        targetOff = $(this);
        clearTimeout(sethover);
        const mouseIsOverWorkaround = (what) => {
            const temp = $(what).parent().find(":hover");
            return temp.length === 1 && temp[0] === what;
          },
          parent = targetOff;
        if (mouseIsOverWorkaround(parent[0])) {
          if (targetOff.hasClass(hoverClass)) {
            clearTimeout(setnexthover);
          }
        } else {
          setleave = setTimeout(function () {
            targetOff.removeClass(hoverClass);
            nowActive = -1;
          }, hoverTime);
        }
      },
    });
  };

$(function () {
  $(window).resize(function () {
    change_window_height();
  });
  change_window_height();
  menu_show_delay($("ul.c-gnavMenu3__list > li"), 250);
});

// Pagetop
$(function () {
  const topBtn = $(".c-pagetop");
  topBtn.hide();
  $(window).scroll(function () {
    if ($(this).scrollTop() > 100) {
      topBtn.fadeIn();
    } else {
      topBtn.fadeOut();
    }
  });
  topBtn.click(function () {
    $("body,html").animate(
      {
        scrollTop: 0,
      },
      500
    );
    return false;
  });
});

const GethashID = (hashIDName) => {
  if (hashIDName) {
    $(".m-tab .m-tab__item")
      .find(".m-tab__link")
      .each(function () {
        const idName = $(this).attr("href");
        if (idName === hashIDName) {
          const parentElm = $(this).parent();
          $(".m-tab .m-tab__item").removeClass("_active_");
          $(parentElm).addClass("_active_");
          $(".m-tab__area").removeClass("_is-active_");
          $(hashIDName).addClass("_is-active_");
        }
      });
  }
};
$(".m-tab a").on("click", function () {
  const idName = $(this).attr("href");
  GethashID(idName);
  return false;
});
$(window).on("load", function () {
  $(".m-tab .m-tab__item:first-of-type").addClass("_active_");
  $(".m-tab__area:first-of-type").addClass("_is-active_");
  const hashName = location.hash;
  GethashID(hashName);
});
